import {
  Button, Card, Checkbox, Col, Form, Input, Layout, Row, Tag
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  generatePath, useHistory, useRouteMatch
} from 'react-router-dom';
import EditVideoSubtitle from '../components/EditVideoSubtitle';
import Header from '../components/Header';
import { UploadVideoBtn } from '../components/UploadVideoBtn/UploadVideoBtn';
import { ROUTES } from '../constants';
import { fetchTags } from '../store/tag/actions';
import { fetchVideo, saveVideo } from '../store/video/actions';
import './EditVideo.less';

export function EditVideo(props) {

  const storedVideo = useSelector(state => state.video);
  const storedTags = useSelector(state => state.tags);
  const history = useHistory();
  let { params } = useRouteMatch(ROUTES.EDIT_VIDEO);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [video, setVideo] = useState(null);
  const [thumbnailUrl, setThumbnailUrl] = useState(null);

  useEffect(() => {
    dispatch(fetchTags());
    if (params.id !== undefined) {
      dispatch(fetchVideo(params.id));
    }
  }, []);
  
  useEffect(() => {
    if (params.id === undefined) {
      setVideo(null);
      form.setFieldsValue({});
      setSelectedTags([]);
    } else {
      setVideo(storedVideo);
      form.setFieldsValue(storedVideo);
      setSelectedTags(storedVideo?.tags || []);
    }
  }, [storedVideo, params.id, form]);

  useEffect(() => {
    setTags(storedTags);
  }, [storedTags]);

  useEffect(() => {
    window.addEventListener('focus', onFocus);

    return () => {
      window.removeEventListener('focus', onFocus);
    };
  }, []);

  const onFocus = () => {
    dispatch(fetchTags());
  };

  const onCancelPress = () => {
    history.goBack();
  };

  const onFinish = (values) => {
    // console.log("onFinish", values);

    const filteredSelectedTags = selectedTags.filter((selectedTag) => {
      return storedTags.findIndex((storedTag) => storedTag.id === selectedTag.id) > -1;
    });

    dispatch(saveVideo({
      ...values,
      isActive: values.isActive === true ? true : false,
      tags: filteredSelectedTags,
      thumbnailUrl: thumbnailUrl||values.thumbnailUrl,
    }, (video) => {
      history.push(generatePath(ROUTES.EDIT_VIDEO, { id: video.id }));
    }));
  };

  const onFinishFailed = (errorInfo) => {
    // console.log(errorInfo);
  };

  const onTagSelectChange = (tag, checked) => {
    const nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter(t => t.id !== tag.id);
    setSelectedTags(nextSelectedTags);
  };

  const onManageTagsPress = () => {
    const tagsPagePath = `${window.location.protocol}//${window.location.host}${ROUTES.TAGS}`;
    window.open(tagsPagePath, '_blank');
  };

  const onUpdateThumbnail = (thumbnailUrl) => {
    setThumbnailUrl(thumbnailUrl);
  };

  return (
    <Layout>
      <Header breadcrumb={'Video'} />
      <Layout.Content className='page-container create-video-container'>
        <Row justify="center">
          <Col span={24} lg={20} xl={16}>
            <Card>
              <Form layout='vertical'
                initialValues={video}
                onFinish={onFinish}
                form={form}
                onFinishFailed={onFinishFailed}
              >
                <Form.Item name='id'>
                  <Input hidden />
                </Form.Item>

                <Form.Item
                  name='title'
                  label='Title'
                  rules={[{
                    required: true,
                    message: 'Title is required!',
                  }]}
                >
                  <Input placeholder='Video title' />
                </Form.Item>

                <Form.Item name='description' label='Description' rules={[{ required: true }]}>
                  <Input placeholder='Video description' />
                </Form.Item>
                <Row>
                  <Col span={24} lg={12}>
                    <Form.Item name='isActive' valuePropName='checked'>
                      <Checkbox>Active in Moodle</Checkbox>
                    </Form.Item>

                    <Form.Item
                      label={(
                        <Row>Tags</Row>
                      )}
                    >
                      {Array.isArray(tags) && tags.map((tag) => (
                        <Tag.CheckableTag
                          key={tag.id}
                          className='tag'
                          checked={selectedTags.findIndex((selectedTag) => selectedTag.id === tag.id) > -1}
                          onChange={(checked) => onTagSelectChange(tag, checked)}
                        >
                          {tag.title}
                        </Tag.CheckableTag>
                      ))}
                      <br />

                      <Button type='link' onClick={onManageTagsPress} style={{ padding: 0 }}>Manage Tags</Button>
                    </Form.Item>
                  </Col>
                  <Col span={24} lg={12}>
                    <div>Thumbnail</div>
                    <div>
                      {(video?.thumbnailUrl?.length > 0 || thumbnailUrl?.length) > 0 ?
                        <img src={thumbnailUrl || video.thumbnailUrl} alt='thumbnail' width='320px' /> :
                        <p style={{ color:'red' }} >No thumbnail</p>}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Form.Item>
                    <Button htmlType='submit'>Submit</Button>
                  </Form.Item>

                  <Form.Item>
                    <Button type='link' htmlType='button' onClick={onCancelPress}>Cancel</Button>
                  </Form.Item>
                </Row>
              </Form>

              <hr />

              {video?.videoFile?.url
                ?
                <EditVideoSubtitle video={video} onUpdateThumbnail={onUpdateThumbnail} />
                :
                <UploadVideoBtn video={video} />
              }
            </Card>
          </Col>
        </Row>
      </Layout.Content>
    </Layout>
  );
}

export default EditVideo;