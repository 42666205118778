import { SearchOutlined } from '@ant-design/icons';
import {
  Button, Card, Col, Input, Layout, Modal, Popconfirm, Row, Table
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../components/Header';
import {
  createApiKey, deleteApiKey, fetchApiKeys
} from '../store/api-key/actions';

export default function ApiKeys() {
  const storedApiKeys = useSelector(state => state.apiKeys);
  const config = useSelector(state => state.config);
  const dispatch = useDispatch();

  const [apiKeys, setApiKeys] = useState([]);
  const [addApiKeyModalVisible, setAddApiKeyModalVisible] = useState(false);
  const [newApiKeyTitle, setNewApiKeyTitle] = useState('');

  useEffect(() => {
    dispatch(fetchApiKeys({}));
  }, []);

  useEffect(() => {
    setApiKeys(storedApiKeys);
  }, [storedApiKeys]);

  const onSearch = (e) => {
    dispatch(fetchApiKeys({ title: e.target.value, }));
  };

  const onCreatePress = () => {
    setAddApiKeyModalVisible(true);
  };

  const onOkAddApiKeyModalPress = () => {
    dispatch(createApiKey({ title: newApiKeyTitle, }));
    setNewApiKeyTitle('');
    setAddApiKeyModalVisible(false);
  };

  const onCancelAddApiKeyModalPress = () => {
    setAddApiKeyModalVisible(false);
  };

  const onDeletePress = (id) => {
    dispatch(deleteApiKey(id));
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Key',
      dataIndex: 'key',
      render: (text, apiKey) => (<>
        <input type='hidden' id={`key_${apiKey.id}`} value={apiKey.key} />
        <Button onClick={() => {
          const key = document.getElementById(`key_${apiKey.id}`).value;
          navigator.clipboard.writeText(key);
        }}>Copy Api Key</Button>
      </>),
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text, apiKey) => (<>
        <div>{new Date(apiKey.createdAt).toLocaleString()}</div>
        <div>by {apiKey?.createdBy?.username}</div>
      </>),
    },
    {
      title: 'Action',
      key: 'action',
      width: 150,
      render: (text, apiKey) => (
        <Popconfirm
          title={'Do you really want to delete this apiKey?'}
          onConfirm={() => onDeletePress(apiKey.id)}
          okText={'Yes'}
          cancelText={'No'}
        >
          <Button>Delete</Button>
        </Popconfirm>
      ),
    }
  ];

  return (
    <Layout>
      <Header breadcrumb={'ApiKeys'} />
      <Layout.Content className='apiKeys-page'>
        <Row justify="center">
          <Col span={24} lg={20} xl={20}>
            <Card>
              <Row justify='space-between'>
                <Col span={16}>
                  <Input prefix={<SearchOutlined />} placeholder={'Search apiKeys'} onChange={onSearch} />
                </Col>
                <Col>
                  <Button onClick={onCreatePress}>New</Button>
                </Col>
              </Row>

              <Table
                style={{ marginTop: 20 }}
                columns={columns}
                dataSource={apiKeys}
                rowKey={'id'}
                pagination={{
                  showTotal: (total, range) => `Total: ${total}`,
                  pageSize: 10,
                }}
              />
            </Card>
          </Col>
        </Row>
        <Row justify="center" style={{ marginTop:'20px' }}>
          <Col className='mt-10' span={24} lg={20} xl={20}>
            <Card  title="Azure Parameters">
              <dl>
                <dt>azureStorageAccount</dt>
                <dd>{config.azureStorageAccount}</dd>
                <dt>azureContainerName</dt>
                <dd>{config.azureContainerName}</dd>
                <dt>azureStorageToken</dt>
                <dd>
                  
                  <input type='hidden' id={'azure_token'} value={config.azureStorageToken} />
                  <Button onClick={() => {
                    const key = document.getElementById('azure_token').value;
                    navigator.clipboard.writeText(key);
                  }}>Copy Azure Token</Button>
                </dd>
              </dl>
            </Card>
          </Col>
        </Row>
      </Layout.Content>
      <Modal title="New ApiKey" visible={addApiKeyModalVisible} onOk={onOkAddApiKeyModalPress} onCancel={onCancelAddApiKeyModalPress}>
        <Input value={newApiKeyTitle} onChange={(e) => { setNewApiKeyTitle(e.target.value); }} placeholder="Enter a new apiKey" />
      </Modal>
    </Layout>
  );
}