import * as types from './types';

export function fetchApiKeys(payload) {
  console.log('action fetch');
  return {
    type: types.FETCH_API_KEYS,
    payload,
  };
}

export function deleteApiKey(payload) {
  return {
    type: types.DELETE_API_KEY,
    payload,
  };
}

export function createApiKey(payload) {
  return {
    type: types.CREATE_API_KEY,
    payload,
  };
}

