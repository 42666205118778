import { PageHeader, Breadcrumb } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../constants';
import HamburgerMenu from './components/HamburgerMenu';
import './styles.less';

const Header = (props) => {

  return (
    <PageHeader
      title={'Babelium NG'}
      extra={[
        <HamburgerMenu key="burgermenu"/>
      ]}>
      <Breadcrumb>
        <Breadcrumb.Item><Link to={ROUTES.HOME}>Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item>
          {props.breadcrumb}
        </Breadcrumb.Item>
       
      </Breadcrumb>
    </PageHeader>
  );
};

export default Header;