import * as types from './types';

export function fetchTags(payload) {
  return {
    type: types.FETCH_TAGS,
    payload,
  };
}

export function deleteTag(payload) {
  return {
    type: types.DELETE_TAG,
    payload,
  };
}

export function createTag(payload) {
  return {
    type: types.CREATE_TAG,
    payload,
  };
}

