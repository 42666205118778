import { notification } from 'antd';
import {
  call, put, select, takeEvery 
} from 'redux-saga/effects';
import { TagService } from '../../services';
import * as types from './types';
import { waitFor } from '../shared/sagas';

function* fetchTags(action) {
  try {
    yield call(waitFor, state => state.token != null);
    const { token } = yield select((state) => state);
    const tagService = yield call(() => new TagService(token));

    const tags = yield call([
      tagService,
      tagService.getTags,
    ], action?.payload);

    yield put({
      type: types.SET_TAGS,
      payload: tags,
    });

  } catch (error) {
    console.error('fetchTags error', error);
  }
}

function* createTag(action) {
  try {
    yield call(waitFor, state => state.token != null);
    const { token } = yield select((state) => state);
    const tagService = yield call(() => new TagService(token));

    const result = yield call([
      tagService,
      tagService.createTag,
    ], action.payload);

    if (result.statusCode === 400) {
      throw new Error(result.message);
    } else {
      yield call(fetchTags);
    }
  } catch (error) {
    notification.error({ message:error.message });
    console.error('createTag error', error);
  }
}

function* deleteTag(action) {
  try {
    yield call(waitFor, state => state.token != null);
    const { tags, token } = yield select((state) => state);
    const tagService = yield call(() => new TagService(token));

    yield call([
      tagService,
      tagService.deleteTag,
    ], action.payload);

    yield put({
      type: types.SET_TAGS,
      payload: tags.filter(i => i.id !== action.payload),
    });

  } catch (error) {
    console.error('deleteTag error', error);
  }
}

export function* tagsSagas() {
  yield takeEvery(types.FETCH_TAGS, fetchTags);
  yield takeEvery(types.CREATE_TAG, createTag);
  yield takeEvery(types.DELETE_TAG, deleteTag);
}

export default tagsSagas;
