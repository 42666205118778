import PropTypes from 'prop-types';
import {
  Button, Col, notification, Row 
} from 'antd';
import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { useDispatch } from 'react-redux';
import { saveSubtitle } from '../../store/video/actions';
import CustomVideoPlayerControlPanel from './components/CustomVideoPlayerControlPanel';
import EditItems from './components/EditItems';
import './styles.less';
import { UploadVideoBtn } from '../UploadVideoBtn/UploadVideoBtn';
import { v4 as uuid } from 'uuid';

const itemTemplate = {
  from: null, to: null, role: '', text: '' 
};

export const EditVideoSubtitle = (props) => {

  const [currentSeek, setCurrentSeek] = useState(0);
  const [totalDurationOfVideo, setTotalDurationOfVideo] = useState(0);
  const [items, setItems] = useState([]);
  const [itemErrors, setItemErrors] = useState([]);
  const [roles, setRoles] = useState(['Paul', 'Paula']);
  const [itemsChanged, setItemsChanged] = useState(false);
  // const [selectedItemIndex, setSelectedItemIndex] = useState(-1);
  const [selectedItemUuid, setSelectedItemUuid] = useState(null);
  const [fromFocused, setFromFocused] = useState(false);
  const [toFocused, setToFocused] = useState(false);

  const dispatch = useDispatch();

  let { video, onUpdateThumbnail } = props;

  let videoPlayerRef = React.createRef();

  useEffect(() => {
    setItems(video.subtitle?.data?.items.map(item => { item.uuid = item.uuid||uuid(); return item;}) || []);
    setRoles(video.subtitle?.data?.roles || []);
  }, [video]);


  const onVideoProgress = (e) => {
    setCurrentSeek(e.playedSeconds);
  };

  const onVideoReady = () => {
    if (videoPlayerRef) {
      setTotalDurationOfVideo(videoPlayerRef.current.getDuration());
    }
  };

  const updateItems = (items) => {

    setItems(items);
    setItemsChanged(true);
  };

  const validateSubtitles = () => {
    for (const item of items) {
      if (item.from === null || item.to === null) {
        return false;
      }
    }
    return true;
  };

  const onSavePress = () => {
    if (!validateSubtitles()) {
      notification.error({ message: 'Cannot save. Subtitles are not valid' });
      return;
    }

    if (video) {
      dispatch(saveSubtitle({
        video,
        data: {
          roles,
          items,
        }
      }));
      setItemsChanged(false);
    }
  };

  // TODO: 
  const validateTimeframe = (from, to) => {
    if ([null, undefined].includes(from) || [null, undefined].includes(to)) {
      return true;
    }

    if (from >= to) {
      notification.error({
        message: 'Timeframe error',
        description: 'End value must be greater than start',
        maxCount: 1
      });
      return false;
    } else {
      return true;
    }
  };

  const addNewRole = (role) => {
    let newRoles = [...roles];
    newRoles.push(role);
    setRoles(newRoles);
  };

  const onDeleteRole = (role) => {
    let newRoles = [...roles];
    let newItems = [...items];
    newRoles = newRoles.filter((r) => r !== role);
    newItems = newItems.map((item) => {
      if (item.role === role) {
        item.role = '';
      }
      return item;
    });
    setRoles(newRoles);
    setItems(newItems);
    
  };

  const updateSelectedItem = (key, value) => {
    
    let selectedItemIndex = items.findIndex(item => item.uuid === selectedItemUuid);
    if (selectedItemIndex > -1) {
      const updatedItems = [...items];
      updatedItems[selectedItemIndex][key] = value;
      setItems(updatedItems);
    }
  };

  const onStartPress = () => {
    if (validateTimeframe(currentSeek, getSelectedItem()?.to)) {
      updateSelectedItem('from', currentSeek);
    }
  };

  const onStopPress = () => {
    if (validateTimeframe(getSelectedItem()?.from, currentSeek)) {
      updateSelectedItem('to', currentSeek);
    }
  };

  const getSelectedItem = () => {
    return items.find(item => item.uuid === selectedItemUuid);    
  };

  const setTimeFocused = (fromFocused, toFocused) => {
    setFromFocused(fromFocused);
    setToFocused(toFocused);  
  };

  const isStartDisabled = () => {
    const selectedItem = getSelectedItem();
    return (!selectedItem || selectedItem.from !== null) && !fromFocused;
  };

  const isStopDisabled = () => {
    const selectedItem = getSelectedItem();
    return (!selectedItem || selectedItem.from === null || selectedItem.to !== null) && !toFocused;
  };

  const onSelectItem = (uuid) => {
    setSelectedItemUuid(uuid);
    let currentItem = items.find((item) => item.uuid === uuid);
    if(currentItem) {
      videoPlayerRef.current.seekTo(currentItem.from, 'seconds');
    }
  };

  const captureVideoFrame = (video, format, quality) => {
    if (typeof video === 'string') {
      video = document.getElementById(video);
    }

    format = format || 'jpeg';
    quality = quality || 0.92;
    video.setAttribute('crossOrigin', 'anonymous');

    if (!video || (format !== 'png' && format !== 'jpeg')) {
      return false;
    }

    // var canvas = document.getElementById("canvas");
    var canvas = document.createElement('CANVAS');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    canvas.getContext('2d').drawImage(video, 0, 0);

    var dataUri = canvas.toDataURL('image/' + format, quality);
    var data = dataUri.split(',')[1];
    var mimeType = dataUri.split(';')[0].slice(5);

    var bytes = window.atob(data);
    var buf = new ArrayBuffer(bytes.length);
    var arr = new Uint8Array(buf);

    for (var i = 0; i < bytes.length; i++) {
      arr[i] = bytes.charCodeAt(i);
    }

    var blob = new Blob([arr], { type: mimeType });
    return {
      blob: blob, dataUri: dataUri, format: format 
    };
  };

  const onTakeThumbnail = () => {
    // console.log(videoPlayerRef);
    if (videoPlayerRef) {
      const frame = captureVideoFrame(videoPlayerRef.current.getInternalPlayer());
      onUpdateThumbnail(frame.dataUri);
    }
  };


  // console.log(video)
  return (
    <div className='edit-video-subtitle-container'>
      <Row>
        <Col span={12}>
          <ReactPlayer
            width='100%'
            url={video?.videoFile?.url}
            controls={true}
            ref={videoPlayerRef}
            onProgress={onVideoProgress}
            progressInterval={100}
            onReady={onVideoReady}
            config={{
              file: {
                attributes: {
                  crossOrigin: 'true',
                  type: 'video/mp4'
                }
              }
            }}
          />
          <CustomVideoPlayerControlPanel
            currentSeek={currentSeek}
            totalDurationOfVideo={totalDurationOfVideo}
            items={items}
            onStartPress={onStartPress}
            onStopPress={onStopPress}
            isStartDisabled={isStartDisabled()}
            isStopDisabled={isStopDisabled()}
            onTakeThumbnail={onTakeThumbnail.bind(this)}
            onSelectItem={onSelectItem}
            selectedItemUuid={selectedItemUuid}
            itemErrors = {itemErrors}

          />
        </Col>

        <Col span={12}>
          <EditItems
            items={items}
            onUpdateItems={updateItems}
            roles={roles}
            onAddNewRole={addNewRole}
            onDeleteRole={onDeleteRole}
            onSelectItem={onSelectItem}
            selectedItemUuid={selectedItemUuid}
            onTimeFocused={setTimeFocused}
            itemErrors = {itemErrors}
            setItemErrors = {setItemErrors}
          />
        </Col>
      </Row>
      <Row className='buttons-row'>
        <Col span={12}>
          <UploadVideoBtn video={video} />
        </Col>
        <Col span={12}>
          <Button className='save-btn' disabled={!itemsChanged} onClick={onSavePress}>Save Subtitles</Button>
        </Col>
      </Row>
    </div>
  );
};

EditVideoSubtitle.propTypes = {
  onUpdateThumbnail: PropTypes.func,
  video: PropTypes.shape({
    subtitle: PropTypes.shape({
      data: PropTypes.shape({
        items: PropTypes.array,
        roles: PropTypes.array
      })
    }),
    videoFile: PropTypes.shape({ url: PropTypes.any })
  })
};
