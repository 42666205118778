import * as types from './types';

const moduleReducers = { users: setUsers };



function setUsers(state = [], action) {
  switch (action.type) {
    case types.SET_USERS:
      return action.payload;
    default:
      return state;
  }
}

export default moduleReducers;