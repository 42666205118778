import * as types from './types';

const moduleReducers = { 
  token: setToken, 
  loggingIn: setLoggingIn, 
  authenticatedUser: setAuthenticatedUser, 
};

function setToken(state = null, action) {
  switch (action.type) {
    case types.SET_TOKEN:
      return action.payload;
    default:
      return state;
  }
}

function setLoggingIn(state = true, action) {
  switch (action.type) {
    case types.SET_LOGING_IN:
      return action.payload;
    default:
      return state;
  }
}

function setAuthenticatedUser(state = null, action) {
  switch (action.type) {
    case types.SET_AUTHNETICATED_USER:
      return action.payload;
    default:
      return state;
  }
}
export default moduleReducers;