import { notification } from 'antd';
import {
  call, put, select, takeEvery 
} from 'redux-saga/effects';
import { ApiKeyService } from '../../services';
import * as types from './types';
import { waitFor } from '../shared/sagas';

function* fetchApiKeys(action) {
  try {
    yield call(waitFor, state => state.token != null);
    const { token } = yield select((state) => state);
    const apiKeyService = yield call(() => new ApiKeyService(token));
    console.log('fetch api key');
    const apiKeys = yield call([
      apiKeyService,
      apiKeyService.getApiKeys,
    ]);

    yield put({
      type: types.SET_API_KEYS,
      payload: apiKeys,
    });

  } catch (error) {
    console.error('fetchApiKeys error', error);
  }
}

function* createApiKey(action) {
  try {
    yield call(waitFor, state => state.token != null);
    const { token } = yield select((state) => state);
    const apiKeyService = yield call(() => new ApiKeyService(token));

    const result = yield call([
      apiKeyService,
      apiKeyService.createApiKey,
    ], action.payload);

    if (result.statusCode === 400) {
      throw new Error(result.message);
    } else {
      yield call(fetchApiKeys);
    }
  } catch (error) {
    notification.error({ message:error.message });
    console.error('createApiKey error', error);
  }
}

function* deleteApiKey(action) {
  try {
    yield call(waitFor, state => state.token != null);
    const { apiKeys, token } = yield select((state) => state);
    const apiKeyService = yield call(() => new ApiKeyService(token));

    yield call([
      apiKeyService,
      apiKeyService.deleteApiKey,
    ], action.payload);

    yield put({
      type: types.SET_API_KEYS,
      payload: apiKeys.filter(i => i.id !== action.payload),
    });

  } catch (error) {
    console.error('deleteApiKey error', error);
  }
}

export function* apiKeysSagas() {
  yield takeEvery(types.FETCH_API_KEYS, fetchApiKeys);
  yield takeEvery(types.CREATE_API_KEY, createApiKey);
  yield takeEvery(types.DELETE_API_KEY, deleteApiKey);
}

export default apiKeysSagas;
