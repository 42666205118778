import { SearchOutlined } from '@ant-design/icons';
import {
  Button, Card, Col, Input, Layout, Modal, Popconfirm, Row, Table 
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../components/Header';
import {
  createTag, deleteTag, fetchTags 
} from '../store/tag/actions';
import './Tags.less';

export default function Tags() {
  const storedTags = useSelector(state => state.tags);
  const dispatch = useDispatch();

  const [tags, setTags] = useState([]);
  const [addTagModalVisible, setAddTagModalVisible] = useState(false);
  const [newTag, setNewTag] = useState('');

  useEffect(() => {
    dispatch(fetchTags({}));
  }, []);

  useEffect(() => {
    setTags(storedTags);
  }, [storedTags]);

  const onSearch = (e) => {
    dispatch(fetchTags({ title: e.target.value, }));
  };

  const onCreatePress = () => {
    setAddTagModalVisible(true);
  };

  const onOkAddTagModalPress = () => {
    dispatch(createTag({ title: newTag, }));
    setNewTag('');
    setAddTagModalVisible(false);
  };

  const onCancelAddTagModalPress = () => {
    setAddTagModalVisible(false);
  };

  const onDeletePress = (id) => {
    dispatch(deleteTag(id));
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Action',
      key: 'action',
      width: 150,
      render: (text, tag) => (
        <Popconfirm
          title={'Do you really want to delete this tag?'}
          onConfirm={() => onDeletePress(tag.id) }
          okText={'Yes'}
          cancelText={'No'}
        >
          <Button>Delete</Button>
        </Popconfirm>
      ),
    }
  ];

  return (
    <Layout>
      <Header breadcrumb={'Tags'} />
      <Layout.Content className='tags-page'>
        <Row justify="center">
          <Col span={24} lg={20} xl={20}>
            <Card>
              <Row justify='space-between'>
                <Col span={16}>
                  <Input prefix={<SearchOutlined/>} placeholder={'Search tags'} onChange={onSearch}/>
                </Col>
                <Col>
                  <Button onClick={onCreatePress}>New</Button>
                </Col>
              </Row>

              <Table
                style={{ marginTop: 20 }}
                columns={columns}
                dataSource={tags} 
                rowKey={'id'}
                pagination={{
                  showTotal: (total, range) => `Total: ${total}`,
                  pageSize: 10,
                }}
              />
            </Card>
          </Col>
        </Row>
      </Layout.Content>
      <Modal title="New Tag" visible={addTagModalVisible} onOk={onOkAddTagModalPress} onCancel={onCancelAddTagModalPress}>
        <Input value={newTag} onChange={(e) => { setNewTag(e.target.value); }} placeholder="Enter a new tag" />
      </Modal>
    </Layout>
  );
}