import ApiService from './api';

export default class ApiKeyService extends ApiService {
  async getApiKeys() {
    return this.get('/api-key/',{});
  }

  async createApiKey(body) {
    return this.post('/api-key/', body);
  }

  async deleteApiKey(id) {
    return this.delete('/api-key/' + id);
  }
}
