import ApiService from './api';

export default class VideoService extends ApiService {

  async getVideo(videoId) {
    return this.get(`/video/${videoId}`);
  }

  async getVideos(params) {
    return this.get('/video/',params);
  }

  async saveVideo(video) {
    if(!video.id) {
      return this.post('/video/', video);
    }else{
      return this.put(`/video/${video.id}`, video);
    }
  }  
  

  async deleteVideo(videoId) {
    return this.delete(`/video/${videoId}`);
  }

  async saveVideoFile(videoFile) {
    return this.post('/video-file/', videoFile);
  }  
  async saveSubtitle(subtitle) {
    console.debug('post subtitle', subtitle);
    return this.post('/subtitle/',  subtitle );
  }


}