import { notification } from 'antd';
import {
  call, put, select, takeEvery 
} from 'redux-saga/effects';
import { UserService } from '../../services';
import * as types from './types';
import * as authTypes from '../auth/types';
import { waitFor } from '../shared/sagas';
import * as dayjs from 'dayjs';
import history from '../../history';
import { ROUTES } from '../../constants';



function* fetchUsers(action) {
  yield call(waitFor, state => state.token != null);
  const { token } = yield select((state) => state);
  const userService = yield call(() => new UserService(token));
  try {
    const users = yield call([
      userService,
      userService.getUsers,
    ],action.payload);

    yield put({
      type: types.SET_USERS,
      payload: users,
    });

  } catch (error) {
    notification.error({
      message: 'Fehler beim Laden der Benutzer',
      description: error.message,
      maxCount: 1
    });
    console.error('fetchUsers error', error);
  }

}

function* updateUser(action) {
  try {
    yield call(waitFor, state => state.token != null);
    const { token } = yield select((state) => state);
    const userService = yield call(() => new UserService(token));

    const result = yield call([
      userService,
      userService.updateUser,
    ], action.payload);
    if (result?.statusCode === 400) {
      throw new Error(result.message);
    } else {
      yield call(fetchUsers, {});
    }
  } catch (error) {
    notification.error({ message:error.message });
    console.error('updateUser error', error);
  }
}

function* deleteUser(action) {
  try {
    yield call(waitFor, state => state.token != null);
    const { token } = yield select((state) => state);
    const userService = yield call(() => new UserService(token));

    yield call([
      userService,
      userService.deleteUser,
    ], action.payload.id);
    notification.success({ message: `User ${action.payload.username} has been deleted`, duration: 60000 });

    yield put({ type: types.FETCH_USERS, });
  } catch (error) {
    notification.error({ message: error.message });
    console.error('deleteUser error', error);
  }
}

function* createInviteCodes(action) {
  try {
    yield call(waitFor, state => state.token != null);
    const { token } = yield select((state) => state);
    const userService = yield call(() => new UserService(token));

    const result = yield call([
      userService,
      userService.createInviteCodes,
    ], action.payload);
    // console.log("createInviteCodes", result);
    if (result?.statusCode === 400) {
      throw new Error(result.message);
    } else {
      let csvContent = 'code;gültig bis\r\n';
      result.forEach((inviteCode) => {
        let row = `${inviteCode.code};${dayjs(inviteCode.validUntil).format('DD.MM.YYYY')}`;
        csvContent += row + '\r\n';
      });
      const url = window.URL.createObjectURL(new Blob([csvContent]));
      const link = document.createElement('a');
      link.setAttribute('download', 'invite_codes.csv');
      link.href = url;
      document.body.appendChild(link);
      link.click();
    }

  } catch (error) {
    notification.error({ message:error.message });
    console.error('create Invite Code error', error);
  }
}

function* checkInviteCode(action){
  try {
    yield call(waitFor, state => state.token != null);
    const { token } = yield select((state) => state);
    const userService = yield call(() => new UserService(token));

    yield call([
      userService,
      userService.checkInviteCode,
    ], action.payload);
    yield put({ type: authTypes.INIT_AUTHENTICATED_USER });
    history.push(ROUTES.BACKEND.DEFAULT);
    notification.success({ message: 'Einladungscode akzeptiert!', duration: 60000 });
  } catch (error) {
    
    notification.error({ message:error.message });
    console.error('check Invite Code error', error, error.code, error.message);
  }
}

export function* userSagas() {
  yield takeEvery(types.FETCH_USERS, fetchUsers);
  yield takeEvery(types.UPDATE_USER, updateUser);
  yield takeEvery(types.DELETE_USER, deleteUser);
  yield takeEvery(types.CREATE_INVITE_CODES, createInviteCodes);
  yield takeEvery(types.CHECK_INVITE_CODE, checkInviteCode);
}

export default userSagas;