import { UploadOutlined } from '@ant-design/icons';
import {
  Button, Progress, Upload 
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { saveVideoFile } from '../../store/video/actions';
import PropTypes from 'prop-types';
import './styles.less';

export const UploadVideoBtn = ({ video }) => {

  const dispatch = useDispatch();

  const [progress, setProgress] = useState(0);
  let resetProgressHandler = null;

  useEffect(() => {
    return () => {
      clearTimeout(resetProgressHandler);
    };
  }, []);

  const uploadVideo = (options) => {
    const {
      onSuccess, onError, file 
    } = options;
    dispatch(saveVideoFile({
      file,
      video,
      onProgress: (uploadProgress) => {
        setProgress(Math.ceil((uploadProgress.loadedBytes / file.size) * 100 ||0) );
      },
      onSuccess: () => {
        onSuccess('Uploaded');
        resetProgressHandler = setTimeout(() => {
          setProgress(0);
        }, 3000);
      },
      onError: (error) => { onError(error); }
    }));
  };

  const onUploadChange = (info) => {
    if (info.file.status !== 'uploading') {
      // console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
      // console.log(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      // console.log(`${info.file.name} file upload failed.`);
    }
  };

  return (
    <Upload
      customRequest={uploadVideo}
      onChange={onUploadChange}
      showUploadList={false}
    >
      <Button icon={<UploadOutlined/>} disabled={!video}>Click to {video?.videoFile?.url? 'Update' : 'Upload'} Video</Button>
      {progress>0 && <Progress className='upload-progress' type="circle" percent={progress} width={40} />}
    </Upload>
  );
};

UploadVideoBtn.propTypes = { video: PropTypes.object, };