import PropTypes from 'prop-types';
import {
  Button, Col, Progress, Row, Slider 
} from 'antd';
import React, { useRef } from 'react';
import {
  PlayCircleOutlined, PauseCircleOutlined, AudioMutedOutlined, AudioOutlined, FileImageOutlined, VerticalRightOutlined, VerticalLeftOutlined 
} from '@ant-design/icons';
import '../styles.less';

export default function CustomVideoPlayerControlPanel(props) {
  const {
    totalDurationOfVideo,
    currentSeek,
    items,
    onStartPress,
    onStopPress,
    onTakeThumbnail,
    isStartDisabled,
    isStopDisabled,
    selectedItemUuid,
    onSelectItem,
    itemErrors
  } = props;

  const getRecordingFrames = () => {

    let tmpRecordingFrames = [];
    let lastRecEnd = 0;
    items.forEach((subtitle, index) => {
      if (subtitle.from >= lastRecEnd) {
        tmpRecordingFrames.push({
          doRecord: false,
          lengthPercent: 100 / totalDurationOfVideo * (subtitle.from - lastRecEnd),
          index: -1,
          hasError: false
        });
      }
      if (subtitle.to !== null) {
        tmpRecordingFrames.push({
          doRecord: true,
          lengthPercent: 100 / totalDurationOfVideo * (subtitle.to - subtitle.from),
          index,
          uuid:subtitle.uuid,
          hasError:itemErrors?.[index]?.hasError
        });
        lastRecEnd = subtitle.to;
      }
    });
    tmpRecordingFrames.push({
      doRecord: false,
      lengthPercent: 100 - tmpRecordingFrames.reduce((acc, cur) => acc + cur.lengthPercent, 0),
      index: -1,
      hasError: false
    });
    // console.log(itemErrors,tmpRecordingFrames);
    return tmpRecordingFrames;
  };

  return (
    <><Row className='controls-container'>
      <Col className="timeline" flex="auto">
        <Row justify="start" flex="100%">
          <div style={{
            position:'absolute', 
            top:0, 
            left: `${100/totalDurationOfVideo*currentSeek}%`,
            height: '100%',
            width: '2px',
            backgroundColor: '#587a00',
            zIndex: 1000,
            border:'0px'
          }}></div>
          {getRecordingFrames().map((frame, index) => <Col
            key={`timeline${index}`}
            flex={`${frame.hasError?0:frame.lengthPercent}%`}
            onClick={frame.doRecord ? () => onSelectItem(frame.uuid) : null}
            style={{
              backgroundColor: frame.doRecord ? (selectedItemUuid === frame.uuid ? '#7aa108' : '#b7d437') : 'lightgray',
              height: '20px',
              cursor: frame.doRecord ? 'pointer' : 'default',
            }}
          ></Col>)}
        </Row>
      </Col>
    </Row>
    <Row justify="center">
      <Col className="buttons">
        <Button className='start-stop-btn' disabled={isStartDisabled} onClick={onStartPress}><VerticalRightOutlined /></Button>
        <Button className='start-stop-btn' disabled={isStopDisabled} onClick={onStopPress}><VerticalLeftOutlined /></Button>
        <Button className='start-stop-btn' onClick={onTakeThumbnail}><FileImageOutlined /></Button>
      </Col>
    </Row>
    </>
  );
}

CustomVideoPlayerControlPanel.propTypes = {
  isStartDisabled: PropTypes.bool,
  isStopDisabled: PropTypes.bool,
  items: PropTypes.array,
  onStartPress: PropTypes.func,
  onStopPress: PropTypes.func,
  onTakeThumbnail: PropTypes.func,
  totalDurationOfVideo: PropTypes.number
};
