import * as types from './types';

const moduleReducers = { tags: setTags, };

function setTags(state = [], action) {
  switch (action.type) {
    case types.SET_TAGS:
      return action.payload;
    default:
      return state;
  }
}

export default moduleReducers;
