import {
  LockOutlined,
  LogoutOutlined, MenuOutlined, TagsOutlined, UserOutlined
} from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ROUTES } from '../../../constants';

const HamburgerMenu = (props) => {

  const LOGOUT_PATH = `${window.location.protocol}//${window.location.host}/auth/logout`;
  const history = useHistory();

  const onLogoutPress = () => {
    window.location = LOGOUT_PATH;
  };

  const onTagsPress = () => {
    history.push(ROUTES.TAGS);
  };

  const onApiKeysPress = () => {
    history.push(ROUTES.API_KEYS);
  };

  const onUsersPress = () => {
    history.push(ROUTES.USERS);
  };

  const onToggle = () => { setMenuIsOpen(!menuIsOpen); };
  const authenticatedUser = useSelector(state => state.authenticatedUser);

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const menu = (
    <Menu className='header-menu'>
      <Menu.Item onClick={onTagsPress} className='item' key='tags'>
        <TagsOutlined className='icon' /> Tags
      </Menu.Item>
      {authenticatedUser.isAdmin &&
				<><Menu.Item onClick={onUsersPress} className='item' key='users'>
				  <UserOutlined className='icon' /> Users
				</Menu.Item>
				<Menu.Item onClick={onApiKeysPress} className='item' key='api-keys'>
				  <LockOutlined className='icon' /> Api Keys				  
				</Menu.Item></>
      }
      {authenticatedUser && (
        <Menu.Item onClick={onLogoutPress} className='item' key='logout'>
          <LogoutOutlined className='icon' /> Logout
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} placement='bottomRight' trigger='click'>
      <MenuOutlined className='menu-icon' onClick={onToggle} />
    </Dropdown>
  );
};

HamburgerMenu.defaultProps = {};

HamburgerMenu.propTypes = {};

export default HamburgerMenu;
