import * as types from './types';

const moduleReducers = { config: setConfig };

function setConfig(state = null, action) {
  switch (action.type) {
    case types.SET_CONFIG:
      return action.payload;
    default:
      return state;
  }
}


export default moduleReducers;