import { combineReducers } from 'redux';

import userReducer from './user/reducer';
import authReducer from './auth/reducer';
import configReducer from './config/reducer';
import videoReducer from './video/reducer';
import tagReducer from './tag/reducer';
import apiKeyReducer from './api-key/reducer';

const rootReducer = combineReducers({
  ...userReducer,
  ...authReducer,
  ...configReducer,
  ...videoReducer,
  ...tagReducer,
  ...apiKeyReducer
});

export default rootReducer;
