import * as types from './types';
  
export function fetchVideos(params) {
  return {
    type: types.FETCH_VIDEOS,
    payload: params,
  };
}
export function fetchVideo(id) {
  return {
    type: types.FETCH_VIDEO,
    payload: id,
  };
}
  
export function deleteVideo(id) {
  return {
    type: types.DELETE_VIDEO,
    payload:id,
  };
}
  
export function saveVideo(video, cb = () => {}) {
  return {
    type: types.SAVE_VIDEO,
    payload: { video, cb },
  };
}
export function saveVideoFile(payload) {
  return {
    type: types.SAVE_VIDEO_FILE,
    payload,
  };
}

export function saveSubtitle(payload) {
  return {
    type: types.SAVE_SUBTITLE,
    payload: payload,
  };
}
  
  