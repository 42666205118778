import {
  call, put, select, takeEvery 
} from 'redux-saga/effects';
import { AuthService } from '../../services';
import * as types from './types';
import history from '../../history';
import { ROUTES } from '../../constants';

function* initUser(action) {
  yield call(initToken);
  yield call(initAuthenticatedUser);
}

function* initAuthenticatedUser() {
  let { token } = yield select((state) => state);
    
  if (token) {
    const authService = yield call(() => new AuthService(token));    
    let user = yield call([authService, authService.getAuthenticatedUser]);
    if (user) {
      if(!user.isActivated){
        history.push(`${ROUTES.LOGIN}?error=${encodeURIComponent('Your account is not activated yet. Please contact Admin.')}`);
      }else{
        yield put({
          type: types.SET_AUTHNETICATED_USER,
          payload: user
        });
      }
        
    }
  }
  yield put({
    type: types.SET_LOGING_IN,
    payload: false
  });
}

function* initToken() {
  const authService = yield call(() => new AuthService());
  let tokenResponse = null;
  try {
    tokenResponse = yield call([authService, authService.getToken]);
  } catch (error) {
    console.error('error fetching token: ', error);
  }
  if (tokenResponse) {
    yield put({
      type: types.SET_TOKEN,
      payload: tokenResponse.access_token
    });
  }
}


function* logout(action) {
  try {
    let { user } = yield select((state) => state);
    yield put({
      type: types.SET_AUTHNETICATED_USER,
      payload: null
    });

    if (user) {
      const authService = yield call(() => new AuthService());
      yield call([authService, authService.logout]);
    }
  } catch (error) {
    // yield put({
    //   type: types.NEW_ALERT_ERROR,
    //   payload: { message: 'Error in logout:' + error.message }
    // });
    console.error('login error', error);
  }
}

export function* userSagas() {
  yield takeEvery(types.INIT_USER, initUser);
  yield takeEvery(types.INIT_AUTHENTICATED_USER, initAuthenticatedUser);
  yield takeEvery(types.LOGOUT, logout);
}

export default userSagas;