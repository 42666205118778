import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import Header from './Header';

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {},
    dispatch
  );
}

function mapStateToProps(state) {
  return {};
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Header);
