import * as types from './types';

const moduleReducers = {
  videos: setVideos,
  video: setVideo,
};

function setVideos(state = null, action) {
  switch (action.type) {
    case types.SET_VIDEOS:
      return action.payload;
    default:
      return state;
  }
}
function setVideo(state = null, action) {
  switch (action.type) {
    case types.SET_VIDEO:
      const video = action.payload;
      if (video?.subtitle?.data?.length > 0 && typeof video.subtitle.data === 'string') {
        video.subtitle.data = JSON.parse(video.subtitle.data);
      }
      return action.payload;
    default:
      return state;
  }
}

export default moduleReducers;