import * as types from './types';

const moduleReducers = { apiKeys: setApiKeys, };

function setApiKeys(state = [], action) {
  switch (action.type) {
    case types.SET_API_KEYS:
      return action.payload;
    default:
      return state;
  }
}

export default moduleReducers;
