const baseUrl = `${window.location.protocol}//${window.location.host}`;
const apiUrl = `${baseUrl}/api`;
const HTTP_HEADER_TOTAL_COUNT = 'X-Total-Count';

export default class ApiService {

  constructor(token = null) {
    this.token = token;
  }

  errorMessages = {
    '401':'Not Logged In. Please reload Application.',
    '403':'Access denied!',
    '404':'Ressource not found',
  }

  async get(url, params) {
    try {
      let options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      };
      if (this.token) {
        options.headers.Authorization = `Bearer ${this.token}`;
      }

      const fullUrl = new URL(apiUrl + url);
      fullUrl.search = new URLSearchParams(params).toString();

      const result = await fetch(fullUrl, options);
      const response = await result.json();
      
      if (result.headers.has(HTTP_HEADER_TOTAL_COUNT) && typeof response === 'object') {
        response.totalCount = result.headers.get(HTTP_HEADER_TOTAL_COUNT);
      }
      
      if (result.status !== 200) {
        throw new Error(`${this.errorMessages[result.status]} (${response.message})`);
      }
      return response;
    } catch (err) {
      throw err;
    }
  }

  async post(url, data) {
    try {
      let options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      };

      if (this.token) {
        options.headers.Authorization = `Bearer ${this.token}`;
      }

      const result = await fetch(apiUrl + url, options);
      const response = await result.json();

      if (![200, 201].includes(result.status)) {
        throw new Error(`${this.errorMessages[result.status]} (${response.message})`);
      }

      return response;
    } catch (err) {
      throw err;
    }
  }

  async put(url, data) {
    try {
      let options = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      };
      if (this.token) {
        options.headers.Authorization = `Bearer ${this.token}`;
      }
      const result = await fetch(apiUrl + url, options);
      const response = await result.json();

      if(result.status!==200){
        console.error('~/projects/di.cl.goethe-quiz/frontend/src/services api.js', 'response', response);
        throw new Error(`${this.errorMessages[result.status]} (${response.message})`);
      }
      return response;
    } catch (err) {
      throw err;
    }
  }

  async delete(url) {
    try {
      let options = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
      };
      if (this.token) {
        options.headers.Authorization = `Bearer ${this.token}`;
      }
      const result = await fetch(apiUrl + url, options);
      const response = await result.json();

      if(result.status!==200){
        console.error('~/projects/di.cl.goethe-quiz/frontend/src/services api.js', 'response', response);
        throw new Error(`${this.errorMessages[result.status]} (${response.message})`);
      }
      return response;
    } catch (err) {
      throw err;
    }
  }

  
}
