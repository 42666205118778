import {
  Switch, Router, Route, Redirect 
} from 'react-router-dom';
import history from './history';
import ProtectedRoute from './components/Auth/ProtectedRoute';
import { Component } from 'react';
import PropTypes from 'prop-types';
import { initUser } from './store/auth/actions';
import { initConfig } from './store/config/actions';
import { connect } from 'react-redux';
import { ROUTES } from './constants';
import Login from './pages/Login';
import Home from './pages/Home';
import EditVideo from './pages/EditVideo';
import Tags from './pages/Tags';
import Users from './pages/Users';
import './App.less';
import ApiKeys from './pages/ApiKeys';

class App extends Component {
  static propTypes = {
    initUser: PropTypes.func,
    initConfig: PropTypes.func,
  }
  componentDidMount() {
    const { initUser, initConfig } = this.props;
    initUser();
    initConfig();
  }
  render() {

    return (
      <>
        {/* <Alerts/> */}
        <Router history={history}>
          <Switch>
            <Route path={ROUTES.LOGIN} component={Login} exact />
            <ProtectedRoute path={ROUTES.EDIT_VIDEO} component={EditVideo} />
            <ProtectedRoute path={ROUTES.TAGS} component={Tags} />
            <ProtectedRoute path={ROUTES.USERS} component={Users} />
            <ProtectedRoute path={ROUTES.API_KEYS} component={ApiKeys} />
            <ProtectedRoute path={ROUTES.HOME} component={Home} />
          </Switch>
        </Router>
      </>
    );
  }
}

const mapDispatchToProps = {
  initUser,
  initConfig
};
export default connect(null, mapDispatchToProps)(App);

