import * as types from './types';


export function initUser() {
  return  { type:types.INIT_USER };
}
export function login(id) {
  return {
    type: types.LOGIN,
    payload: { id: id }
  };
}

export function logout() {
  return {
    type: types.LOGOUT,
    payload: {}
  };
}