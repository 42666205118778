import {
  Button, Col, Row, Layout, Table, Tag, Space, Card, Popconfirm 
} from 'antd';
import React, {
  Component, useEffect, useState 
} from 'react';
import Header from '../components/Header';
import './Home.less';
import { Input } from 'antd';
import { ROUTES } from '../constants';
import { useDispatch, useSelector } from 'react-redux';
import { deleteVideo, fetchVideos } from '../store/video/actions';
import {
  Link, useHistory, generatePath 
} from 'react-router-dom';
const { Search } = Input;


export default function Home() {

  const videos = useSelector(state => state.videos);
  const dispatch = useDispatch();
  const history = useHistory();
  const [currentFilter, setCurrentFilter] = useState('');
  const [lastFilter, setLastFilter] = useState(null);
  const [search, setSearch] = useState('');
  
  useEffect(() => {
    if(lastFilter!==currentFilter){
      setLastFilter(currentFilter);
      dispatch(fetchVideos({}));
    }
  }, [currentFilter, dispatch, lastFilter,setLastFilter]);

  const onCreateVideoPress = () => {
    history.push(generatePath(ROUTES.EDIT_VIDEO, { id: null }));
  };

  const onSearchChange = (e) => {
    const { value } = e.target;
    setSearch(value);
    performSearch(value);
  };

  const performSearch = (search) => {
    dispatch(fetchVideos({ title: search, }));
  };

  const confirmDeleteVideo = (id) => { 
    dispatch(deleteVideo(id));
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text, video) => ( <>
        <div>{new Date(video.createdAt).toLocaleString()}</div>
        <div>{video.createdBy && `by ${video.createdBy?.username}`}</div>
      </> ),
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (text, video) => ( <>
        <div>{new Date(video.updatedAt).toLocaleString()}</div>
        <div>{video.updatedBy && `by ${video.updatedBy?.username}`}</div>
      </> ),
    },
    {
      title: 'Thumbnail',
      dataIndex: 'thunbnailUrl',
      key: 'thunbnailUrl',
      render: (text, video) => ( <img src={video.thumbnailUrl} style={{ maxHeight:'50px' }} alt="thumbnail" /> ),       
    },
    
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Button type="link" onClick={() => history.push(generatePath(ROUTES.EDIT_VIDEO, { id: record.id }))}>Edit</Button>
          <Popconfirm
            title="Are you sure to delete this video?"
            onConfirm={()=>confirmDeleteVideo(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link">Delete</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];


  // TODO: add pagination api https://github.com/nestjsx/nestjs-typeorm-paginate
  return (
    <Layout>
      <Header breadcrumb={'Video'} />
      <Layout.Content className='home-page'>
        <Row justify="center">
          <Col span={24} lg={20} xl={20}>
            <Card>
              <Row >
                <Col xs={24} offset={0} className='table-actions'>
                  <Search placeholder='Search' onSearch={performSearch} onChange={onSearchChange} />
                  <Button type='primary' className='create-video-btn' onClick={onCreateVideoPress}>Create video</Button>
                </Col>
              </Row>

              <Row>

                <Col span={24}>
                  <Table loading={videos===null} columns={columns} dataSource={videos} rowKey={'id'} />
                </Col>

              </Row>
            </Card>
          </Col>
        </Row>
      </Layout.Content>
    </Layout>
  );

}


