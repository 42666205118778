import React, { useEffect } from 'react';
import {
  Form, Button, Row, Col, Card 
} from 'antd';

import { useDispatch, useSelector } from 'react-redux';
import {
  useHistory, useParams, useLocation 
} from 'react-router-dom';
import { ROUTES } from '../constants';
import Text from 'antd/lib/typography/Text';
import { logout } from '../store/auth/actions';

const baseUrl = `${window.location.protocol}//${window.location.host}`;
const authBase = `${baseUrl}/auth`;

function parseQuery(queryString) {
  var query = {};
  var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split('=');
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query;
}
export default function Login(props) {
  let user = useSelector(state => state.user);
  let history = useHistory();
  let { search } = useLocation();
  let dispatch = useDispatch();

  let queryParams = parseQuery(search);
  // console.log();
  useEffect(() => {
    // console.log("user is", user);
    if (user?.id) {
      history.push(ROUTES.BACKEND.DEFAULT);
    }
  }, [user]);
  const logoutUser = () => {
    // dispatch(logout)
    window.location = authBase + '/logout';
  };
  const login = () => {
    window.location = authBase + '/casauth?login=1';
  };
  const register = () => {
    window.location = authBase + '/casauth?register=1';
  };


  return (
    <div className='c-home'>
      <Row type="flex" justify="center" align="middle" style={{ minHeight: '100vh' }}>        
        <Col span={12}  align="middle">
          <Card >
            <h2>Babelium NG Admin</h2>
            {queryParams?.error && <p> 
              <Text type="danger">{queryParams.error}</Text>
              <Form.Item>
                <Button type='primary' block onClick={() => logoutUser()}>Login with other MyGoethe</Button>
              </Form.Item>
            </p>}
            <Form.Item>
              <Button type='primary' block onClick={() => login()}>Login with MyGoethe</Button>
            </Form.Item>
            <Form.Item>
              <Button block onClick={() => register()}>Register with MyGoethe</Button>
            </Form.Item>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
