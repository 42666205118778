

const ROUTES = {
  LOGIN:'/login',
  HOME: '/',
  EDIT_VIDEO: '/video/:id?',
  TAGS: '/tags',
  API_KEYS: '/api-keys',
  USERS: '/users',
};

const ALERT = {
  LEVELS: {
    SUCCESS: 'success',
    ERROR: 'error',
    WARNING: 'warning',
    INFO: 'info',
  },
};

export {
  ROUTES,
  ALERT,
};
