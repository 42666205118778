import { SearchOutlined } from '@ant-design/icons';
import {
  Button, Card, Checkbox, Col, Form, Input, Layout, Modal, Popconfirm, Row, Table 
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../components/Header';
import {
  deleteUser, fetchUsers, updateUser 
} from '../store/user/actions';
import './Users.less';

export default function Users() {
  const storedUsers = useSelector(state => state.users);
  const authenticatedUser = useSelector(state => state.authenticatedUser);
  const dispatch = useDispatch();

  const [users, setUsers] = useState([]);
  const [userModalVisible, setUserModalVisible] = useState(false);
  const [userToEdit, setUserToEdit] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(fetchUsers());
  }, []);

  useEffect(() => {
    setUsers(storedUsers);
  }, [storedUsers]);

  const onSearch = (e) => {
    dispatch(fetchUsers({ email: e.target.value, }));
  };

  const onDeletePress = (user) => {
    dispatch(deleteUser(user));
  };

  const onEditPress = (user) => {
    setUserToEdit(user);
    form.setFieldsValue(user);
    setUserModalVisible(true);
  };

  const onCancelUserModalPress = () => {
    setUserModalVisible(false);
    setUserToEdit(null);
  };

  const onFinish = (values) => {
    dispatch(updateUser(values));
    setUserModalVisible(false);
    setUserToEdit(null);
  };

  const columns = [
    {
      title: 'User',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Action',
      key: 'action',
      width: 200,
      render: (text, user) => (
        <>
          <Popconfirm
            title={'Do you really want to delete this user?'}
            onConfirm={() => onDeletePress(user)}
            okText={'Yes'}
            cancelText={'No'}
          >
            <Button disabled={user?.id === authenticatedUser?.id}>Delete</Button>
          </Popconfirm>
          <Button disabled={authenticatedUser.id === user.id} style={{ marginLeft: 10 }} onClick={() => { onEditPress(user); }}>Edit</Button>
        </>
      ),
    }
  ];

  return (
    <Layout>
      <Header breadcrumb={'Users'} />
      <Layout.Content className='users-page'>
        <Row justify="center">
          <Col span={24} lg={20} xl={20}>
            <Card>
              <Row justify='space-between'>
                <Col span={16}>
                  <Input prefix={<SearchOutlined/>} placeholder={'Search users'} onChange={onSearch}/>
                </Col>
              </Row>

              <Table 
                style={{ marginTop: 20 }}
                columns={columns}
                dataSource={users}
                rowKey={'id'}
                pagination={{
                  showTotal: (total, range) => `Total: ${total}`,
                  pageSize: 10
                }} 
              />
            </Card>
          </Col>
        </Row>
      </Layout.Content>
      <Modal title="Edit user" visible={userModalVisible} okButtonProps={{ htmlType: 'submit', form: 'edit-user' }} onCancel={onCancelUserModalPress}>
        <Form
          name='edit-user'
          initialValues={userToEdit}
          onFinish={onFinish}
          form={form}
        >
          <Form.Item noStyle name='id'>
            <Input hidden name='id'/>
          </Form.Item>
          <Form.Item name='firstName' label='Firstname'>
            <Input name='firstName'/>
          </Form.Item>
          <Form.Item name='lastName' label='Lastname'>
            <Input name='lastName'/>
          </Form.Item>
          <Form.Item name='username' label='Username'>
            <Input disabled name='username'/>
          </Form.Item>
          <Form.Item name='email' label='E-Mail'>
            <Input disabled name='email'/>
          </Form.Item>
          <Form.Item name='isAdmin' valuePropName='checked'>
            <Checkbox name='isAdmin'>is Admin</Checkbox>
          </Form.Item>
          <Form.Item name='isActivated' valuePropName='checked'>
            <Checkbox name='isActivated'>is Activated</Checkbox>
          </Form.Item>
        </Form>
      </Modal>
    </Layout>
  );
}