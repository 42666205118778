import React, { memo } from 'react';
import { Redirect } from 'react-router';
import { useSelector } from 'react-redux';
import  PropTypes from 'prop-types';
import { ROUTES } from '../../constants';
// TODO: implement
// import Login from "../../pages/InviteCode"; 
const ProtectedRoute = ({
  component: Comp,
  ...rest
}) => {
  const authenticatedUser = useSelector(state => state.authenticatedUser);
  const loggingIn = useSelector(state => state.loggingIn);
  return authenticatedUser ?
    <Comp {...rest} />:
  // user.isActivated || user.isAdmin ? <Comp {...rest} /> : <Redirect to="/invitecode" /> :
    loggingIn ? <React.Fragment /> : <Redirect to={ROUTES.LOGIN} />;
};
ProtectedRoute.propTypes = { component: PropTypes.func||PropTypes.object, };


export default memo(ProtectedRoute);