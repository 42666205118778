import { fork } from 'redux-saga/effects';
import authSagas from './auth/sagas';
import userSagas from './user/sagas';
import configSagas from './config/sagas';
import videoSagas from './video/sagas';
import tagSagas from './tag/sagas';
import apiKeySagas from './api-key/sagas';

export default function* root() {
  yield fork(authSagas);
  yield fork(userSagas);
  yield fork(configSagas);
  yield fork(videoSagas);
  yield fork(tagSagas);
  yield fork(apiKeySagas);
}
