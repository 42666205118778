import { notification } from 'antd';
import {
  call, put, select, takeEvery 
} from 'redux-saga/effects';
import { VideoService } from '../../services';
import * as types from './types';
import { waitFor } from '../shared/sagas';
import AzureStorage from '../../services/azure_storage';
import { v4 as uuid } from 'uuid';

function* fetchVideos(action) {
  try {
    yield call(waitFor, state => state.token != null);
    const { token } = yield select((state) => state);
    const videoService = yield call(() => new VideoService(token));
    const videos = yield call([
      videoService,
      videoService.getVideos,
    ], action?.payload);
    
    yield put({
      type: types.SET_VIDEOS,
      payload: videos,
    });

  } catch (error) {
    console.error('fetchVideos error', error);
  }
}

function* fetchVideo(action) {
  try {
    yield call(waitFor, state => state.token != null);
    const { token } = yield select((state) => state);
    const videoService = yield call(() => new VideoService(token));

    const video = yield call([
      videoService,
      videoService.getVideo,
    ], action?.payload);
    yield put({
      type: types.SET_VIDEO,
      payload: video,
    });

  } catch (error) {
    console.error('fetchVideos error', error);
  }
}

function* saveVideo(action) {
  yield call(waitFor, state => state.token != null);
  const { token } = yield select((state) => state);

  // console.log("thumbnailUrl",action.payload?.video,action.payload?.video?.thumbnailUrl);
  if (action.payload?.video?.thumbnailUrl?.length ) {
    if (action.payload.video.thumbnailUrl.startsWith('data:')) {
      // console.log('saveVideo', 'saving thumbnail');
      action.payload.video.thumbnailUrl = yield call(saveThumbnail, action.payload.video.thumbnailUrl);
      // console.log(action.payload.video.thumbnailUrl);
    }
  }

  const videoService = yield call(() => new VideoService(token));
  try {
    const video = yield call([
      videoService,
      videoService.saveVideo,
    ], action.payload.video);

    

    action.payload.cb(video);
    notification.success({ message: 'Video saved', duration: 2 });

    yield call(fetchVideos);
    yield put({ type: types.SET_VIDEO, payload: video });

  } catch (error) {
    console.error('saveVideo error', error);
    notification.error({ message: error.message });
  }
}

function* deleteVideo(action) {
  try {
    yield call(waitFor, state => state.token != null);
    const { videos, token } = yield select((state) => state);
    const videoService = yield call(() => new VideoService(token));

    yield call([
      videoService,
      videoService.deleteVideo,
    ], action.payload);

    yield put({
      type: types.SET_VIDEOS,
      payload: videos.filter(i => i.id !== action.payload),
    });

    yield call(fetchVideos);

  } catch (error) {
    notification.error({ message: 'Error deleting Video', description: error.message });
    console.error('deleteVideo error', error);
  }
}

function* saveThumbnail(imgUrl) {
  const { config } = yield select((state) => state);
  let result = null;
  const azureStorage = yield call(() => new AzureStorage(config));
  try {
    let res = yield fetch(imgUrl);
    let blob = yield res.blob();
    let filename = `thumbnails/${uuid()}.jpg`;
    const file = new File([blob], filename, { type: 'image/jpeg' });
    yield call([
      azureStorage,
      azureStorage.storeFile,
    ], file, filename, () => { });
    result = yield call([azureStorage, azureStorage.getUrl], filename);
  } catch (error) {
    notification.error({
      message: 'Error storing thumbnail',
      description: error.message,
      maxCount: 1
    });
    console.error('uploadImage', 'error', error);
  }
  return result;
}

function* saveVideoFile(action) {
  yield call(waitFor, state => state.token != null);
  const { token } = yield select((state) => state);
  const videoService = yield call(() => new VideoService(token));

  try {
    let url = yield call(uploadVideoFile, action.payload);
    let payload = {
      url,
      video: action.payload.video
    };
    action.payload.onSuccess();
    const video = yield call([
      videoService,
      videoService.saveVideoFile,
    ], payload);

    yield put({ type: types.SET_VIDEO, payload: video });

    notification.success({ message: 'VideoFile saved', duration: 2 });

  } catch (error) {
    notification.error({ message: error.message });
    action.payload.onError(error);
    console.error('saveVideoFile error', error);
  }
}

function* uploadVideoFile(payload) {
  const { config } = yield select((state) => state);

  const azureStorage = yield call(() => new AzureStorage(config));
  let filename = `videos/${payload.video.id}/${uuid()}.${payload.file.name.split('.').pop()}`;
  // console.log(filename);
  try {
    yield call([
      azureStorage,
      azureStorage.storeFile,
    ], payload.file, filename, payload.onProgress);
    ;
  } catch (error) {
    notification.error({
      message: 'Error storing video',
      description: error.message,
      maxCount: 1
    });
    console.error('uploadImage', 'error', error);
  }

  let url = yield call([azureStorage, azureStorage.getUrl], filename);

  return url;
}

function* saveSubtitle(action) {
  yield call(waitFor, state => state.token != null);
  const { token } = yield select((state) => state);
  const videoService = yield call(() => new VideoService(token));

  console.debug('saveSubtitle(action)', action);
  console.debug('saveSubtitle(action) action.payload', action.payload);

  try {
    yield call([
      videoService,
      videoService.saveSubtitle
    ], action.payload);
    notification.success({ message: 'Subtitle saved', duration: 2 });

    yield call(fetchVideo, { payload: action.payload?.video?.id });

  } catch (error) {
    console.error('saveSubtitle error', error);
    notification.error({ message: error.message });
  }
}
export function* videosSagas() {
  yield takeEvery(types.FETCH_VIDEOS, fetchVideos);
  yield takeEvery(types.FETCH_VIDEO, fetchVideo);
  yield takeEvery(types.SAVE_VIDEO, saveVideo);
  yield takeEvery(types.SAVE_VIDEO_FILE, saveVideoFile);
  yield takeEvery(types.DELETE_VIDEO, deleteVideo);
  yield takeEvery(types.SAVE_SUBTITLE, saveSubtitle);

}

export default videosSagas;
