import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import {
  Button, Col, Input, Modal, Row, Select 
} from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import '../styles.less';

const { Option } = Select;

const itemTemplate = {
  from: null, to: null, role: '', text: '' 
};
const addRoleOption = 'add-role';

export default function EditItems(props) {
  const {
    onAddNewRole,
    onDeleteRole,
    onUpdateItems,
    roles,
    items,
    onSelectItem,
    selectedItemUuid,
    onTimeFocused,
    itemErrors,
    setItemErrors
  } = props;
  const [roleModalVisible, setRoleModalVisible] = useState(false);
  const [newRole, setNewRole] = useState('');
  const [currentTime, setCurrentTime] = useState(null);
  // const [itemErrors, setItemErrors] = useState([]);

  useEffect(() => {
    validateTimeframes();
  },[]);

  useEffect(() => {    
    validateTimeframes();
  },[items]);

  const validateTimeframes = ()=>{
    let prev = null;
    let newErrors = [...itemErrors];
    items?.forEach((item, index) => {
      newErrors[index] = {};
      if (prev && prev.to > item.from) {
        newErrors[index]['from']=true;
      }else{
        newErrors[index]['from']=false;
      }
      if (item.to < item.from) {
        newErrors[index]['to']=true;
      }else{
        newErrors[index]['to']=false;
      };
      let hasError=Object.values(newErrors[index]).some((value) => value);
      newErrors[index]['hasError']=hasError;
      setItemErrors(newErrors);
      prev = item;
    });

  };

  const onDeleteItemPress = (index) => {
    const updatedItems = [...items];
    updatedItems.splice(index, 1);
    onUpdateItems(updatedItems);
  };

  const onAddItemPress = (e) => {
    e.stopPropagation();
    const newItem = { ...itemTemplate, uuid: uuid() };
    const updatedItems = [...items, newItem];
    onUpdateItems(updatedItems);
    onSelectItem(newItem.uuid);
  };

  const onItemChange = (index, key, value, sort=false) => {
    const updatedItems = [...items];
    updatedItems[index][key] = value;

    if(sort) {
      updatedItems.sort((a, b) => a.from - b.from);
    }
    onUpdateItems(updatedItems);
  };

  const updateErrors = (key, index, isInvalid) => {
    let newErrors = [...itemErrors];
    newErrors[index] = newErrors[index] || {};
    newErrors[index][key] = isInvalid;
    newErrors[index]['hasError'] = Object.values(newErrors[index]).some((value) => value);
    setItemErrors(newErrors);
  };

  const isTimeValid = (value) => {
    return /^(\d\d:?){1,3}(\.)?(\d){0,3}$/.test(value);
  };



  const onUpdateTime = (key, index, value) => {
    // onTimeFocused(false, false);
    if (isTimeValid(value)) {
      let seek = 0;
      let [hms, ms] = value.split('.');
      const parts = hms.split(':');
      if (parts.length === 3) {
        seek = parseInt(parts[0]) * 60 * 60 + parseInt(parts[1]) * 60 + parseInt(parts[2]);
      } else if (parts.length === 2) {
        seek = parseInt(parts[0]) * 60 + parseInt(parts[1]);
      } else {
        seek = parseInt(parts[0]);
      }
      seek += ms ? parseInt(ms) / 1000 : 0;      
      onItemChange(index, key, seek, true);
    }
  };

  const onItemTimeChange = (key, index, value) => {

    let isValid = isTimeValid(value);
    let newCurrentTime = { ...currentTime };
    newCurrentTime[key] = value;
    setCurrentTime(newCurrentTime);
    if (isValid) {
      updateErrors(key, index, false);
    } else {
      updateErrors(key, index, true);
    }
  };

  const onRolePress = (index, value) => {
    if (value === addRoleOption) {
      setRoleModalVisible(true);
    } else {
      onItemChange(index, 'role', value);
    }
  };

  const onEnterNewRole = (event) => {
    event.preventDefault();
    onAddNewRole(newRole);
    setNewRole('');
    // setRoleModalVisible(false);
  };


  const onDeleteRolePress = (role)=>{
    onDeleteRole(role);
  };

  const onItemPress = (e, index) => {
    e.stopPropagation();
    onSelectItem(items[index].uuid);
  };


  const seekToTime = (seek) => {
    let result = '';
    let seekMs = Math.round(seek * 1000);
    let ms = seekMs % 1000;
    let seekS = (seekMs - ms) / 1000;
    // s = (s - ms) / 1000;
    var secs = seekS % 60;
    let seekM = (seekS - secs) / 60;
    var mins = seekM % 60;
    var hrs = (seekM - mins) / 60;
    if (hrs > 0) result = String(hrs).padStart(2, '0') + ':';
    return result + String(mins).padStart(2, '0') + ':' + String(secs).padStart(2, '0') + '.' + String(ms).padStart(3, '0');
  };

  return <div className='items-container' onClick={() => { onSelectItem(null); }}>

    {Array.isArray(items) && items.map((item, index) => {
      const isSelected = selectedItemUuid === item.uuid;
      const hasError = itemErrors?.[index]?.hasError||false;
      return (
        <div key={`${index}`} >
          <Row className={`item-row subtitle-meta ${hasError?'error':false} ${isSelected && 'selected'}`} onClick={(e) => { onItemPress(e, index); }}>
            <Col className='item-number' span={1}>
              <div>{index + 1}</div>
            </Col>
            <Col span={13}>
              <Input.Group compact>
                <Input
                  value={(currentTime?.from && selectedItemUuid === item.uuid) ? currentTime?.from : seekToTime(item.from)}
                  style={{ width: '45%' }}
                  status={itemErrors?.[index]?.['from'] ? 'error' : null}
                  onChange={(e) => onItemTimeChange('from', index, e.target.value)}
                  // onBlur={(e)=> onItemTimeChange('from', index, e.target.value)}
                  onBlur={(e) => onUpdateTime('from', index, e.target.value)}
                  onFocus={(e) => { onTimeFocused(true, false); setCurrentTime(seekToTime(item.from)); }}
                />
                {' - '}
                <Input
                  value={(currentTime?.to && selectedItemUuid === item.uuid) ? currentTime?.to : seekToTime(item.to)}
                  style={{ width: '45%' }}
                  status={itemErrors?.[index]?.to ? 'error' : null}
                  onChange={(e) => onItemTimeChange('to', index, e.target.value)}
                  onBlur={(e) => onUpdateTime('to', index, e.target.value)}
                  onFocus={(e) => { onTimeFocused(false, true); setCurrentTime(seekToTime(item.to));}}
                />
              </Input.Group>
            </Col>
            <Col span={8} className='role-select-container'>
              <Select value={item.role}
                placeholder='Role'
                style={{ width: '100%' }}
                onChange={(value) => { onRolePress(index, value); }}>
                {Array.isArray(roles) && roles.map((roleOption, index) => (
                  <Option key={`${index}`} value={roleOption}>{roleOption}</Option>
                ))}
                <Option key={'add-role'} value={addRoleOption}>Edit Roles</Option>
              </Select>
            </Col>
            <Col span={2} className='delete-item-container'>
              <DeleteOutlined onClick={() => { onDeleteItemPress(index); }} />
            </Col>
          </Row>
          <Row
            className={`item-row subtitle  ${hasError && 'error'}  ${isSelected && 'selected'}`}
            onClick={(e) => { onItemPress(e, index); }}>
            <Col span={1}>
            </Col>
            <Col span={21}>
              <Input
                value={item.text}
                onChange={(e) => { onItemChange(index, 'text', e.target.value); }}
                placeholder='Enter text' />
            </Col>
            <Col span={2}></Col>
          </Row>
        </div>
      );
    })}

    <Row className='add-item-container'>
      <Button onClick={onAddItemPress} className='add-item-btn'>
        <PlusCircleOutlined />
      </Button>
    </Row>

    <Modal title="Edit Roles" visible={roleModalVisible} footer={null} onCancel={()=>setRoleModalVisible(false)}>
      {roles?.map((role, index) => <Row key={`role${index}`}>
        <Col flex='auto'>{role}</Col>
        <Col flex={'20%'}><DeleteOutlined onClick={() =>  onDeleteRolePress(role)} /></Col>
      </Row>)}
      <form onSubmit={onEnterNewRole} >
        <Input value={newRole} onChange={(e) => { setNewRole(e.target.value); }} placeholder="Enter a new role" />
      </form>
    </Modal>
  </div>;

}

EditItems.propTypes = {
  onAddNewRole: PropTypes.func,
  onUpdateItems: PropTypes.func,
  roles: PropTypes.array,
  items: PropTypes.array,
  onSelectItem: PropTypes.func,
};

